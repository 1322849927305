import { FC, useEffect } from 'react';
import RoundWrapper from '../RoundWrapper/RoundWrapper';
import { PreviewRound } from '../types';
import { useInView } from 'react-intersection-observer';
import useAnswer from '../hooks/useAnswer';
import './Preview.scss';

const Preview: FC<PreviewRound> = (props) => {
    const { content, showHeader } = props;
    const { ref, inView } = useInView({ triggerOnce: true });

    const winDetector = (answer: boolean) => answer;

    const { setAnswer, isWin } = useAnswer<boolean>(winDetector);

    useEffect(() => {
        if (inView) setAnswer(true);
    }, [inView]);

    return (
        <RoundWrapper title={content.title} withoutResetButton isWin={isWin} showHeader={showHeader}>
            {content.renderDescription !== undefined && (
                <div className="preview-description">
                    {content.renderDescription({ images: content.images, videos: content.videos })}
                </div>
            )}
            <div ref={ref} />
        </RoundWrapper>
    );
};

export default Preview;
