import { INITIAL_LIVES_NUMBER } from '../constants';
import { BALLOON_COLOR } from './Balloons/Balloon/Balloon';
import CertificateDescription from './CertificateDescription/CertificateDescription';
import { CORRECT_EXPLANATION_ASSETS, DEFAULT_TIMER_DURATION, INCORRECT_EXPLANATION_ASSETS } from './constants';
import { Round, RoundType } from './types';
import { getRandomFromArray } from './utils';

export const roundsConfig: Round[] = [
    // 0
    {
        type: RoundType.PREVIEW,
        content: {
            images: ['https://assets.happybirthdaydianayasenko.com/config1/content/cat_birthday.jpg'],
            renderDescription: ({ images }) => {
                return (
                    <>
                        <p>
                            <strong>Доктор Диана Михайловна 👩🏻‍⚕️</strong>, на связи Женя 👨‍💻, как вы там сегодня? 🙃
                        </p>
                        <p>Ходят слухи, что у тебя день рождения!</p>
                        <img src={images?.[0]} alt="birthday cat" height="300" />
                        <p>
                            А день рождения <strong>лучшей девушки в мире</strong> я пропустить не могу!!! 😜 Поэтому я{' '}
                            думал-думал, и придумал для тебя кое-что. Только не спрашивай меня <q>что это?</q> Я сам не
                            знаю 😅. Но будет прикольно!
                        </p>
                        <p>
                            Правда, придётся постараться 🙂. Так что, если тебе интересно, что же там дальше, у тебя
                            хорошее настроение (с плохим не пускаем!) и немножко времени, то устраивайся поудобнее и
                            начинай 🚀 А вдруг в конце что-то будет, кто его знает... 🙃
                        </p>
                        <small>
                            P.S. Я, конечно, сделал, чтобы работало везде, например на твоём новом айфоне 📱, но
                            желательно на компьютере 💻 или хотя бы не в браузере телеги, но вообще как хочешь, всё для
                            именинницы 😅.
                        </small>
                    </>
                );
            },
        },
        showHeader: false,
        logMessage: 'Start of the game',
    },
    // 1
    {
        type: RoundType.SIMPLE_QUIZ,
        content: {
            isSingleChoice: true,
            title: {
                text: 'Как твоё настроение?',
            },
            options: [
                {
                    text: 'Отличное! 🥳',
                    explanation: {
                        text: 'В таком случае пускаем! 🚀',
                        renderAsset: () => getRandomFromArray(CORRECT_EXPLANATION_ASSETS),
                    },
                },
                {
                    text: 'Плохое 😔',
                    explanation: {
                        text: 'Не, с плохим настроением правда низя 😶',
                        renderAsset: () => getRandomFromArray(INCORRECT_EXPLANATION_ASSETS),
                    },
                },
            ],
            correctOptionIndexes: [0],
        },
    },
    // 2
    {
        type: RoundType.SIMPLE_QUIZ,
        content: {
            isSingleChoice: true,
            title: {
                text: 'А вот скажи, какой лучший подарок на день рождения?',
            },
            options: [
                {
                    text: 'Тортик 🎂',
                    explanation: {
                        text: 'Не, от тортиков вообще-то толстеют. Можно ненадо 😶',
                        renderAsset: () => getRandomFromArray(INCORRECT_EXPLANATION_ASSETS),
                    },
                },
                {
                    text: 'Айфончик 📱',
                    explanation: {
                        text: 'BOOOOOOOORING 🥱 И ваще у тебя уже есть.',
                        renderAsset: () => getRandomFromArray(INCORRECT_EXPLANATION_ASSETS),
                    },
                },
                {
                    text: 'Путешествие 🌍',
                    explanation: {
                        text: 'Неплохо, но нет. А ещё путешествовать нужно в правильной компании 🙃',
                        renderAsset: () => getRandomFromArray(INCORRECT_EXPLANATION_ASSETS),
                    },
                },
                {
                    text: 'Что угодно от Жени 🧑‍💻',
                    explanation: {
                        text: 'Правильно! Только Женя гарантированно доставит улыбку и кринж, а о чём ещё мечтает именинница 🤪',
                        renderAsset: () => getRandomFromArray(CORRECT_EXPLANATION_ASSETS),
                    },
                },
            ],
            correctOptionIndexes: [3],
        },
    },
    // 3
    {
        type: RoundType.SIMPLE_QUIZ,
        content: {
            isSingleChoice: true,
            title: {
                text: 'ВАЖНЫЙ ВОПРОС!!! Так что в итоге, я удалил аккаунт в инсте или заблокировал тебя? 🤔',
            },
            options: [
                {
                    text: 'Удалил аккаунт 🗑️',
                    explanation: {
                        text: 'Нет, у меня он хороший, фоточки классные, воспоминания. Зачем удалять? 🙂',
                        renderAsset: () => getRandomFromArray(INCORRECT_EXPLANATION_ASSETS),
                    },
                },
                {
                    text: 'Просто временно деактивировал аккаунт ⏳',
                    explanation: {
                        text: 'Всего-то 🙃 Но я рад что тебе, хоть и через несколько месяцев, стало интересно 🤗 Не забивай себе голову и продолжай!',
                        renderAsset: () => getRandomFromArray(CORRECT_EXPLANATION_ASSETS),
                    },
                },
                {
                    text: 'Заблокировал тебя 🚫',
                    explanation: {
                        text: 'Диан, ну ты шо, ваще 🤯? Ну как можно в принципе подумать, что я заблокирую тебя? 😱 Какие-то плохие люди тебе попадались в жизни, видимо. Пора изменить мнение обо мне. А ещё, на всякий случай, сестре изменить, а то может она до сих пор с мозга кекает 😅',
                        renderAsset: () => getRandomFromArray(INCORRECT_EXPLANATION_ASSETS),
                    },
                },
            ],
            correctOptionIndexes: [1],
        },
    },
    // 4
    {
        type: RoundType.PREVIEW,
        content: {
            images: ['https://assets.happybirthdaydianayasenko.com/config1/content/me_help.jpg'],
            renderDescription: ({ images }) => (
                <>
                    <p>Диана, мне хорошо вбились в память две вещи из наших бесед:</p>
                    <ol style={{ listStylePosition: 'inside' }}>
                        <strong>
                            <li>Ты часто плачешь на свой день рождения 😪</li>
                        </strong>
                        <strong>
                            <li>И часто грустишь, когда люди уходят 🚶‍♂️</li>
                        </strong>
                    </ol>
                    <p>
                        <cite>
                            А за вакном плача лістапад
                            <br />
                            Напэўна ён хоча расказаць
                            <br />
                            Маўклівы сон дзе ў тваіх вачах
                            <br />
                            Замірае час 🎶
                        </cite>
                    </p>
                    <p>
                        Не плачь сегодня, хорошо? 😳 Я хочу, чтобы ты была весёлой, счатливой, с улыбкой до ушей 😃. И
                        не только сегодня, а каждый день!!! Именно такой я тебя запомнил, именно такой я хочу тебя
                        знать. А сегодня я попытаюсь помочь тебе в этом больше чем обычно 🤗
                    </p>
                    <img src={images?.[0]} height={300} />
                    <p>
                        А по поводу того, что люди уходят. За всех не отвечаю, но тут вопросики не ко мне 🙂. Никуда я
                        не ухожу, и не важно, не общаемся мы по какой-нибудь очередной дурацкой причине или общаемся. Я
                        всегда где-то рядом.
                    </p>
                    <p>
                        Так что, никакой грусти! <strong>Это приказ!!!</strong> У тебя день рождения, и это должен быть
                        лучший день в твоей жизни 🥳
                    </p>
                    <p>Пошли дальше 😜</p>
                </>
            ),
        },
        showHeader: false,
    },
    // 5
    {
        type: RoundType.SIMPLE_QUIZ,
        content: {
            images: [
                'https://assets.happybirthdaydianayasenko.com/config1/content/artemida.png',
                'https://assets.happybirthdaydianayasenko.com/config1/content/areopag.jpg',
                'https://assets.happybirthdaydianayasenko.com/config1/content/demetra.png',
                'https://assets.happybirthdaydianayasenko.com/config1/content/aphrodita.png',
                'https://assets.happybirthdaydianayasenko.com/config1/content/aphina.png',
            ],
            isSingleChoice: true,
            title: {
                text: 'Слушай, такой прикол, шёл шёл, и забыл. Типичный Женя 🤦‍♂️. А ты, случайно, не помнишь, кто такая Диана в греческой мифологии?',
            },
            options: [
                {
                    text: 'Артемида (богиня охоты, богиня женского целомудрия, покровительница всего живого на Земле)',
                    renderAsset: ({ images }) => <img src={images?.[0]} />,
                    explanation: {
                        text: 'Аааа, блин, точно! Ты права 💯. Когда я был в Афинах, то на всякий случай всем богам поклонился, но в первую очередь богине Диане 😇',
                        renderAsset: ({ images }) => <img src={images?.[1]} />,
                    },
                },
                {
                    text: 'Деметра (богиня плодородия, покровительница земледелия)',
                    renderAsset: ({ images }) => <img src={images?.[2]} />,
                    explanation: {
                        text: 'Не. Деметра - это Дмитрий, один Дмитрий у меня уже есть, второго не нужно 😅',
                        renderAsset: () => getRandomFromArray(INCORRECT_EXPLANATION_ASSETS),
                    },
                },
                {
                    text: 'Афродита (богиня красоты и любви)',
                    renderAsset: ({ images }) => <img src={images?.[3]} />,
                    explanation: {
                        text: 'Женя считает, что Афродита тебе в подмётки не годится, но наука так не считает 😁',
                        renderAsset: () => getRandomFromArray(INCORRECT_EXPLANATION_ASSETS),
                    },
                },
                {
                    text: 'Афина (богиня мудрости, военной стратегии и тактики)',
                    renderAsset: ({ images }) => <img src={images?.[4]} />,
                    explanation: {
                        text: 'Какая-то воинственная богиня ☠️. Ты точно не такая. Нипутю тебя воевать',
                        renderAsset: () => getRandomFromArray(INCORRECT_EXPLANATION_ASSETS),
                    },
                },
            ],
            correctOptionIndexes: [0],
        },
    },
    // 6
    {
        type: RoundType.SIMPLE_QUIZ,
        content: {
            images: [
                'https://assets.happybirthdaydianayasenko.com/config1/content/aleksandria.jpg',
                'https://assets.happybirthdaydianayasenko.com/config1/content/dubai.jpg',
                'https://assets.happybirthdaydianayasenko.com/config1/content/lukashenko.jpg',
            ],
            isSingleChoice: true,
            title: {
                text: 'Я, кстати, чота много где побывал в последнее время. Но ничего не сравнится с этим местом. Ты даже не представляешь, насколько я там преисполнился в своём сознании. Считаю, что каждый сознательный гражданин должен там побывать. А где? 🤔',
                renderAsset: ({ images }) => <img src={images?.[0]} />,
            },
            options: [
                {
                    text: 'Дубай 🇦🇪',
                    explanation: {
                        text: 'Дубай прикольный 🏙, необычный, вон у сестрёнки спроси, уверен что подтвердит. Мне понравилось. Но не сравнится с другим местом!',
                        renderAsset: ({ images }) => <img src={images?.[1]} />,
                    },
                },
                {
                    text: 'Вильнюс 🇱🇹',
                    explanation: {
                        text: 'Думаю, что есть места и поинтереснее, чем этот город твоей первой поездки в Европу 😅. И кринж, и ответ неправильный',
                        renderAsset: () => getRandomFromArray(INCORRECT_EXPLANATION_ASSETS),
                    },
                },
                {
                    text: 'Варшава 🇵🇱',
                    explanation: {
                        text: '"Без впечатлений" 😅 Не расстраиваемся, всё впереди! Но ответ неправильный',
                        renderAsset: () => getRandomFromArray(INCORRECT_EXPLANATION_ASSETS),
                    },
                },
                {
                    text: 'Александрия 🇧🇾',
                    explanation: {
                        text: 'Маладзец, чэсна выграла этат раунд 👍 Диана 97 працэнтау!',
                        renderAsset: ({ images }) => <img src={images?.[2]} />,
                    },
                },
            ],
            correctOptionIndexes: [3],
        },
    },
    // 7
    {
        type: RoundType.PREVIEW,
        content: {
            images: [
                'https://assets.happybirthdaydianayasenko.com/config1/content/bologna_med_1.jpg',
                'https://assets.happybirthdaydianayasenko.com/config1/content/bologna_med_2.jpg',
                'https://assets.happybirthdaydianayasenko.com/config1/content/bologna_med_3.jpg',
                'https://assets.happybirthdaydianayasenko.com/config1/content/bologna_med_4.jpg',
                'https://assets.happybirthdaydianayasenko.com/config1/content/barefoot.jpg',
            ],
            renderDescription: ({ images }) => (
                <>
                    <p>
                        Кстати, недавно в Болонье был. Кроме того, что местный царёк отменил футбол ⚽, на который я
                        прилетел (как видишь, всё стабильно 😂), она также известна тем, что там был создан первый в
                        мире университет. И на этих фото <strong>доктор Ясенко Диана Михайловна 👩🏻‍⚕️</strong> может
                        увидеть первый медицинский факультет 🏥. Программисты отстают в развитии лет на 800 🤭
                    </p>
                    <div style={{ display: 'flex', flexDirection: 'column', rowGap: '2rem', marginBottom: '1rem' }}>
                        <img src={images?.[0]} />
                        <img src={images?.[1]} />
                        <img src={images?.[2]} />
                        <img src={images?.[3]} />
                        <img src={images?.[4]} />
                    </div>
                    <small>
                        (Не спрашивай, почему он босой. Но оказалось, что это моя компания на несколько дней. Зато было
                        весело 😂)
                    </small>
                </>
            ),
        },
        showHeader: false,
    },
    // 8
    {
        type: RoundType.SIMPLE_QUIZ,
        content: {
            videos: [
                'https://assets.happybirthdaydianayasenko.com/config1/content/penalty_question.webm',
                'https://assets.happybirthdaydianayasenko.com/config1/content/penalty_answer.webm',
            ],
            isSingleChoice: true,
            title: {
                text: 'Ничего, мы всегда найдём себе какой-нибудь рандомный футбол 😁. Парма ⚪ - Эмполи 🔵. Ваши ставки, будет гол или нет? ',
                renderAsset: ({ videos }) => (
                    <video autoPlay loop muted playsInline>
                        <source src={videos?.[0]} type="video/webm" />
                    </video>
                ),
            },
            options: [
                {
                    text: 'Дааааа, конечно забьёт, он же не лох 🎯',
                    explanation: {
                        text: 'Получается что лох, потому что ответ неправильный 🤣',
                        renderAsset: () => getRandomFromArray(INCORRECT_EXPLANATION_ASSETS),
                    },
                },
                {
                    text: 'Не, без шансов 🚫',
                    explanation: {
                        text: 'Не Диана, а Ванга какая-то! Умеешь, могёшь 😑. Скажи, даже ты попала бы, да? 😏',
                        renderAsset: ({ videos }) => (
                            <video autoPlay loop muted playsInline>
                                <source src={videos?.[1]} type="video/webm" />
                            </video>
                        ),
                    },
                },
            ],
            correctOptionIndexes: [1],
        },
    },
    // 9
    {
        type: RoundType.SIMPLE_QUIZ,
        content: {
            images: [
                'https://assets.happybirthdaydianayasenko.com/config1/content/tattoo_diana.jpg',
                'https://assets.happybirthdaydianayasenko.com/config1/content/tattoo_dybala.jpg',
                'https://assets.happybirthdaydianayasenko.com/config1/content/tattoo_belarus.jpg',
            ],
            isSingleChoice: true,
            title: {
                text: 'Кстати, я тут, пока не общаемся, решил себе татуировку сделать, как-то раньше против был, а сейчас захотелось 🥹. Пока что варианты такие. Ты бы какой выбрала?',
            },
            options: [
                {
                    text: 'Вариант раз',
                    renderAsset: ({ images }) => <img src={images?.[0]} />,
                    explanation: {
                        text: '"Тихо шифером шурша", крыша едет, но настолько не доедет, не дождёшься 😂😂😂😂😂😂😂😂😂😂😂😂😂😂😂',
                        renderAsset: () => getRandomFromArray(INCORRECT_EXPLANATION_ASSETS),
                    },
                },
                {
                    text: 'Вариант два',
                    renderAsset: ({ images }) => <img src={images?.[1]} />,
                    explanation: {
                        text: 'Ты что, это ж бывший игрок Ювентуса 😱, а Женя за Милан болеет!',
                        renderAsset: () => getRandomFromArray(INCORRECT_EXPLANATION_ASSETS),
                    },
                },
                {
                    text: 'Вариант три',
                    renderAsset: ({ images }) => <img src={images?.[2]} />,
                    explanation: {
                        text: 'Симпатичная, если в Беларусь не ездить 😅',
                        renderAsset: () => getRandomFromArray(INCORRECT_EXPLANATION_ASSETS),
                    },
                },
                {
                    text: 'Никакую',
                    explanation: {
                        text: 'Ну конечно же я шучу 😜 Какое тату. Я и так красивый',
                        renderAsset: () => getRandomFromArray(CORRECT_EXPLANATION_ASSETS),
                    },
                },
            ],
            correctOptionIndexes: [3],
        },
    },
    // 10
    {
        type: RoundType.SIMPLE_QUIZ,
        content: {
            images: [
                'https://assets.happybirthdaydianayasenko.com/config1/content/trousers_formal.webp',
                'https://assets.happybirthdaydianayasenko.com/config1/content/trousers_sporty.webp',
                'https://assets.happybirthdaydianayasenko.com/config1/content/trousers_bell_bottom.webp',
                'https://assets.happybirthdaydianayasenko.com/config1/content/trousers_casual.webp',
            ],
            title: {
                text: 'Ну ладно, с татуировкой была шутка. Шутеечка. А вот штаны мне реально нужны. Старые прям закончились 😶. Выбери, плиз, за меня, а то я разрываюсь. Все сразу хочу, но блин, только трое могу себе позволить',
            },
            options: [
                {
                    text: 'Штаны раз',
                    renderAsset: ({ images }) => <img src={images?.[0]} />,
                },
                {
                    text: 'Trousers two',
                    renderAsset: ({ images }) => <img src={images?.[1]} />,
                },
                {
                    text: 'Штаны тры',
                    renderAsset: ({ images }) => <img src={images?.[2]} />,
                },
                {
                    text: 'Spodnie cztery',
                    renderAsset: ({ images }) => <img src={images?.[3]} />,
                },
            ],
            correctOptionIndexes: [0, 1, 3],
            winExplanation: {
                text: 'Урааааа! Женя не будет ходить с голой жопкой! 🎉 Без тебя бы не справился 🤗',
                renderAsset: () => getRandomFromArray(CORRECT_EXPLANATION_ASSETS),
            },
            loseExplanation: {
                text: 'Я должен объяснять почему ваш выбор неправильный? 😆',
                renderAsset: () => getRandomFromArray(INCORRECT_EXPLANATION_ASSETS),
            },
        },
        hintOptions: [
            { text: 'Нужно выбрать 3 варианта', showAt: 1 },
            { text: 'Нужно совсем не клеш 😅', showFrom: 2 },
        ],
    },
    // 11
    {
        type: RoundType.PREVIEW,
        content: {
            images: ['https://assets.happybirthdaydianayasenko.com/config1/content/cat_meme.webp'],
            renderDescription: ({ images }) => (
                <>
                    <p>Дианка, смотрю у тебя неплохо получается 😉</p>
                    <img src={images?.[0]} height={200} />
                    <p>
                        Тут, кстати, видишь 👀, жизни сверху ❤️. Можно случайно умереть, и даже не от смеха 🤭. Я решил,
                        что 9 жизней, как у всех котиков - это слишком легко, поэтому дал котику Диане{' '}
                        {INITIAL_LIVES_NUMBER} жизней. Если что, отреанимируешь себя 😛.
                    </p>
                    <p>
                        <q>Есть ашчушчэние</q>, что дальше будет ещё интереснее и увлекательнее. Но ты невероятно
                        настойчивая и умная. Да да, я серьёзно, не отнекивайся, не пытайся мне тут скромничать. Именно
                        ты 🫵 - самая умненькая. Поэтому ты справишься 💪
                    </p>
                </>
            ),
        },
    },
    // 12
    {
        type: RoundType.INPUT_CORRECT_ANSWER,
        content: {
            videos: ['https://assets.happybirthdaydianayasenko.com/config1/content/nephew_first_video.webm'],
            title: {
                text: 'Есть в этом мире один человечек, которого ты очень любишь? Какого числа он родился? (смотри, там таймер сверху ⏲️, ааааа, нужно быстрее отвечать 👻)',
            },
            correctAnswer: ['25', '25-ого', '25ого', '25-го', '25го', 'двадцать пятого', 'двадцать пять'],
            winExplanation: {
                text: 'Ну конечно же это Тимофей! Как ты сказала, маленький, но уже вольны ✊',
                renderAsset: ({ videos }) => (
                    <video autoPlay loop muted playsInline>
                        <source src={videos?.[0]} type="video/webm" />
                    </video>
                ),
            },
            loseExplanation: {
                text: 'Не, ну это кринж 🤪🤪🤪 Конечно же это неправильный ответ',
                renderAsset: () => getRandomFromArray(INCORRECT_EXPLANATION_ASSETS),
            },
        },
        timerOptions: {
            duration: DEFAULT_TIMER_DURATION,
            loseByTimerExplanation: {
                text: 'Время вышло! 😱',
                renderAsset: () => getRandomFromArray(INCORRECT_EXPLANATION_ASSETS),
            },
        },
        hintOptions: [{ text: 'Мааааааленький такой человечек 🥰', showFrom: 1 }],
    },
    // 13
    {
        type: RoundType.INPUT_CORRECT_ANSWER,
        content: {
            images: ['https://assets.happybirthdaydianayasenko.com/config1/content/nephew_with_diana.jpg'],
            title: {
                text: 'Принимаю благодарность за зарядку для головы, ну или для рук, "доктор" Женя заботится о твоём здоровье 😄 Ещё для глаз сделай, а то будешь слепой как я 🤓. А сколько грамм он весил, силач Диана? 🏋️‍♀️ ',
                renderAsset: ({ images }) => <img src={images?.[0]} />,
                isUpsideDown: true,
            },
            correctAnswer: [
                '3790',
                '3790гр',
                '3790гр.',
                '3790 гр',
                '3790 гр.',
                '3790г',
                '3790г.',
                '3790 г',
                '3790 г.',
                '3790 грамм',
                '3790 граммов',
                'три тысячи семьсот девяносто',
                'три тысячи семьсот девяносто грамм',
                'три тысячи семьсот девяносто граммов',
            ],
            winExplanation: {
                text: 'В точку! Интересно, а сколько сейчас? 🙃',
                renderAsset: () => getRandomFromArray(CORRECT_EXPLANATION_ASSETS),
            },
            loseExplanation: {
                text: 'Стыдно тёте и крёстной не знать 😔. Спроси у сестры 😅',
                renderAsset: () => getRandomFromArray(INCORRECT_EXPLANATION_ASSETS),
            },
        },
        hintOptions: [{ text: 'Цифирька в граммах, начинается с 3790 😁', showFrom: 2 }],
    },
    // 14
    {
        type: RoundType.PRESS_BUTTON,
        content: {
            images: ['https://assets.happybirthdaydianayasenko.com/config1/content/nephew_fun_photo.jpg'],
            title: {
                text: 'Какая же ты, всё-таки, хорошая тётя! 😍 Но просто быть хорошей недостаточно, так ведь? Свою любовь ❤️ нужно показывать и доказывать на деле 😉',
                renderAsset: ({ images }) => <img src={images?.[0]} />,
            },
            winExplanation: {
                text: 'Это не тётя, а герой какой-то! Надеюсь пальчик не болит. Дай подую 😮‍💨 Но это того стоило! Теперь сможешь не просто говорить ему, что любишь, а конкретно рассказывать, как ради него страдала 😅',
                // TODO: bug, renderAsset is called twice because of changing "overlayVisible". As temporary solution I use static asset
                renderAsset: () => CORRECT_EXPLANATION_ASSETS[3],
            },
        },
    },
    // 15
    {
        type: RoundType.BALLOONS,
        content: {
            title: {
                text: 'Лопай толькі БЧБ шарыкі! Ніводны не павінен выляцець 🎈',
            },
            correctColors: [BALLOON_COLOR.RED, BALLOON_COLOR.WHITE],
            loseExplanation: {
                text: 'Ну як так, Дыяна? 😱 Нешта сумненька стала. А такія спадзяванні на цябе ўскладала грамадства. Уяві сабе, што ад гэтага залежыць лёс краіны! 🔴⚪🔴',
                renderAsset: () => getRandomFromArray(INCORRECT_EXPLANATION_ASSETS),
            },
            happyBirthdayName: 'Diana',
        },
        language: 'by',
    },
    // 16
    {
        type: RoundType.PREVIEW,
        content: {
            title: {
                text: 'Давно не виделись 😉',
            },
            renderDescription: () => (
                <iframe
                    src="https://www.youtube.com/embed/0-Bwm1ffa7I"
                    title="Делаем вид что не интересно, но всё равно кликаем и смотрим 🙂"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                    style={{ border: 'none', aspectRatio: '9 / 16', height: '70vh' }}
                />
            ),
        },
        showHeader: false,
    },
    // 17
    {
        type: RoundType.INPUT_CORRECT_ANSWER,
        content: {
            images: ['https://assets.happybirthdaydianayasenko.com/config1/content/teddy_bear_hostage.jpg'],
            title: {
                text: 'Адрес',
                renderAsset: ({ images }) => <img src={images?.[0]} />,
            },
            correctAnswer: ['*'],
            winExplanation: {
                text: 'Посмотрим потом, что ты там написала 🙂',
                renderAsset: () => getRandomFromArray(CORRECT_EXPLANATION_ASSETS),
            },
        },
        inputPlaceholder: 'Но помни про слежку 🫣',
        showHeader: false,
        logMessage: 'Address',
    },
    // 18
    {
        type: RoundType.SIMPLE_QUIZ,
        content: {
            isSingleChoice: true,
            title: {
                text: 'Ну ведь прикольно же, да? 🙃',
            },
            options: [
                {
                    text: 'Нет',
                    explanation: {
                        text: 'Ну, тогда я обиделся, и дальше не пойдём 😞',
                        renderAsset: () => getRandomFromArray(INCORRECT_EXPLANATION_ASSETS),
                    },
                },
                {
                    text: 'Да',
                    explanation: {
                        text: 'Неправильно. Так бы сказал кто-то, но не Диана 👩🏻‍⚕️',
                        renderAsset: () => getRandomFromArray(INCORRECT_EXPLANATION_ASSETS),
                    },
                },
                {
                    text: 'Да',
                    explanation: {
                        text: 'Неправильно. Так бы сказал кто-то, но не Диана 👩🏻‍⚕️',
                        renderAsset: () => getRandomFromArray(INCORRECT_EXPLANATION_ASSETS),
                    },
                },
                {
                    text: 'Дaaaaa',
                    explanation: {
                        text: '"Ты узнаешь ее из тысячи 🎶" 👩🏻‍⚕️',
                        renderAsset: () => getRandomFromArray(CORRECT_EXPLANATION_ASSETS),
                    },
                },
            ],
            correctOptionIndexes: [3],
        },
    },
    // 19
    {
        type: RoundType.SIMPLE_QUIZ,
        content: {
            isSingleChoice: true,
            title: {
                text: 'Именинница 🎂, а себя ты хорошо знаешь?) Давай проверим. Вот какой у тебя рост? 🤭',
            },
            options: [
                {
                    text: '169 см.',
                    explanation: {
                        text: 'Правильно!!! Твой рост и правда 172 😆, идеальный рост! 🎉',
                        renderAsset: () => getRandomFromArray(CORRECT_EXPLANATION_ASSETS),
                    },
                },
                {
                    text: '190.5 см.',
                    explanation: {
                        text: 'Вы себя с кем-то перепутали. С кем-то, кто начинается на "Ж" и заканчивается на "еня" 🤣',
                        renderAsset: () => getRandomFromArray(INCORRECT_EXPLANATION_ASSETS),
                    },
                },
                {
                    text: '172 см.',
                    explanation: {
                        text: 'И теперь, когда ты, наконец, согласилась, ты не имеешь морального права со мной спорить 😅',
                        renderAsset: () => getRandomFromArray(CORRECT_EXPLANATION_ASSETS),
                    },
                },
                {
                    text: '170 см.',
                    explanation: {
                        text: '"Почти" не считается 😁',
                        renderAsset: () => getRandomFromArray(INCORRECT_EXPLANATION_ASSETS),
                    },
                },
            ],
            correctOptionIndexes: [0, 2],
        },
    },
    // 20
    {
        type: RoundType.SIMPLE_QUIZ,
        content: {
            isSingleChoice: true,
            title: {
                text: 'Што ж гэта быў за цудоўны дзень тыдня, калі ты нарадзілася?',
            },
            options: [
                {
                    text: 'Панядзелак',
                    explanation: {
                        text: 'Ахах, не, у панядзелак гэта мы нарадзіліся. І гэтая песня тлумачыць усё ў далейшым жыцці 😂',
                        renderAsset: () => (
                            <iframe
                                width="320"
                                height="180"
                                src="https://www.youtube.com/embed/goFIxxWru78?si=ta7I3XEs6xDKl0qY"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin"
                                allowFullScreen
                                style={{ border: 'none' }}
                            />
                        ),
                    },
                },
                {
                    text: 'Аўторак',
                    explanation: {
                        text: 'Не, аўторак гэта сёлета...',
                        renderAsset: () => getRandomFromArray(INCORRECT_EXPLANATION_ASSETS),
                    },
                },
                {
                    text: 'Нядзеля',
                    explanation: {
                        text: '"Які цудоўны дзень! Які цудоўны пень" 😂 Але Дыяна таксама цудоўная!!! 🤩',
                        renderAsset: () => getRandomFromArray(CORRECT_EXPLANATION_ASSETS),
                    },
                },
            ],
            correctOptionIndexes: [2],
        },
        language: 'by',
    },
    // 21
    {
        type: RoundType.SIMPLE_QUIZ,
        content: {
            images: ['https://assets.happybirthdaydianayasenko.com/config1/content/diana_walk.jpg'],
            isSingleChoice: true,
            title: {
                text: 'Але нагадай, хто там цябе, такую цудоўную, баіцца? 🙀',
            },
            options: [
                {
                    text: 'Людзі 🗿',
                    explanation: {
                        text: 'За ўсіх не адказваю, але быццам не баяцца 😅',
                        renderAsset: () => getRandomFromArray(INCORRECT_EXPLANATION_ASSETS),
                    },
                },
                {
                    text: 'Качкі 🦆',
                    explanation: {
                        text: 'Я не ведаю, чаму баяліся 😂😂😂 Але памятаеш, баяліся ж, праўда! Хоць наогул "у цябе лапкі" і павінны прымаць за сваю 🤭',
                        renderAsset: ({ images }) => <img src={images?.[0]} />,
                    },
                },
                {
                    text: 'Коцікі 🐈',
                    explanation: {
                        text: 'Не, ты што, ну як коцікі могуць баяцца коціка? 🐱',
                        renderAsset: () => getRandomFromArray(INCORRECT_EXPLANATION_ASSETS),
                    },
                },
                {
                    text: 'Жэня 👨‍💻',
                    explanation: {
                        text: 'Ахахахах, ну які Жэня 😂 Дыяна, ты мілая і добрая, словы "ты" і "небяспека" не могуць стаяць у адным сказе)',
                        renderAsset: () => getRandomFromArray(INCORRECT_EXPLANATION_ASSETS),
                    },
                },
            ],
            correctOptionIndexes: [1],
        },
        language: 'by',
    },
    // 22
    {
        type: RoundType.PUZZLE,
        content: {
            images: ['https://assets.happybirthdaydianayasenko.com/config1/content/diana_puzzle.jpg'],
            title: {
                text: 'Соберись, тряпка! 🧩',
            },
            imageUrl: 'https://assets.happybirthdaydianayasenko.com/config1/content/diana_puzzle.jpg',
            winExplanation: {
                text: 'Красотка! 😍😍😍',
                renderAsset: () => getRandomFromArray(CORRECT_EXPLANATION_ASSETS),
            },
        },
    },
    // 23
    {
        type: RoundType.SIMPLE_QUIZ,
        content: {
            images: ['https://assets.happybirthdaydianayasenko.com/config1/content/diana_egg.jpg'],
            title: {
                text: 'Што з гэтага пра цябе праўда? Мусіш выбраць усе правільныя варыянты ✅',
                renderAsset: ({ images }) => <img src={images?.[0]} />,
            },
            options: [
                { text: 'Ты займалася танцамі 💃🏻' },
                { text: 'У цябе крывы рот 🫤' },
                { text: 'Стала б настаўнікам працоўнага навучання 👩🏻‍🏫' },
                { text: 'Masz piękną nogę 🦵🏻' },
            ],
            correctOptionIndexes: [0, 2, 3],
            winExplanation: {
                text: 'Усё, афіцыйна заяўляю, что можна перастаць мяне гэтым троліць 🤡 Прабач мяне ўжо, калі такое і было 🙏',
                renderAsset: () => getRandomFromArray(CORRECT_EXPLANATION_ASSETS),
            },
            loseExplanation: {
                text: 'Вось і глядзі зараз гэты сумны мем, таму што Дыяна адказала няправільна 😞',
                renderAsset: () => getRandomFromArray(INCORRECT_EXPLANATION_ASSETS),
            },
        },
        language: 'by',
        hintOptions: [{ text: '3 правільныя варыянты', showFrom: 1 }],
    },
    // 24
    {
        type: RoundType.SIMPLE_QUIZ,
        content: {
            images: [
                'https://assets.happybirthdaydianayasenko.com/config1/content/diana_best_photo_1.jpg',
                'https://assets.happybirthdaydianayasenko.com/config1/content/diana_best_photo_2.jpg',
                'https://assets.happybirthdaydianayasenko.com/config1/content/diana_best_photo_3.jpg',
                'https://assets.happybirthdaydianayasenko.com/config1/content/diana_best_photo_4.jpg',
            ],
            isSingleChoice: true,
            title: {
                text: 'Фотачкі! Як ты лічыш, якое з тваіх фота маё улюбёнае? 📷',
            },
            options: [
                {
                    text: '"Дыяна есць 🍱"',
                    renderAsset: ({ images }) => <img src={images?.[0]} />,
                },
                {
                    text: '"Дыяна цяпер лекар 🎓"',
                    renderAsset: ({ images }) => <img src={images?.[1]} />,
                },
                {
                    text: '"Дыяна выносіць смецце 🗑️"',
                    renderAsset: ({ images }) => <img src={images?.[2]} />,
                },
                {
                    text: '"Дыяна заўзее за Ювентус ⚪⚫"',
                    renderAsset: ({ images }) => <img src={images?.[3]} />,
                },
            ],
            winExplanation: {
                text: 'Ну вядома ж усе фота самыя любімыя! Але, як кажуць, усе роўныя, але першае крыху раўней за іншых 😜',
                renderAsset: () => getRandomFromArray(CORRECT_EXPLANATION_ASSETS),
            },
            correctOptionIndexes: [0, 1, 2, 3],
        },
        language: 'by',
    },
    // 25
    {
        type: RoundType.SIMPLE_QUIZ,
        content: {
            images: [
                'https://assets.happybirthdaydianayasenko.com/config1/content/food_eggs.jpg',
                'https://assets.happybirthdaydianayasenko.com/config1/content/food_peppers.jpg',
                'https://assets.happybirthdaydianayasenko.com/config1/content/food_fruits.jpg',
                'https://assets.happybirthdaydianayasenko.com/config1/content/food_poop.jpg',
                'https://assets.happybirthdaydianayasenko.com/config1/content/food_moti.jpg',
            ],
            title: {
                text: 'Якая з гэтых страў - тваё тварэнне?',
            },
            options: [
                {
                    text: 'Яйкі 🥚',
                    renderAsset: ({ images }) => <img src={images?.[0]} />,
                },
                {
                    text: 'Перчыкі 🫑',
                    renderAsset: ({ images }) => <img src={images?.[1]} />,
                },
                {
                    text: 'Ежа багоў 🍉🍑🫐',
                    renderAsset: ({ images }) => <img src={images?.[2]} />,
                },
                {
                    text: 'Какашкі 💩',
                    renderAsset: ({ images }) => <img src={images?.[3]} />,
                },
            ],
            correctOptionIndexes: [0, 2],
            winExplanation: {
                text: 'Диана протянула мне тарелку, сказала, что это "моти". Я отломил кусочек, положил его в рот i тут быццам цуд нейкi наогул здзейснiўся, адразу ўсё стала прыемным, як подых ветрыка звечару ўлетку, нiбыта птушкi лётаюць блiзенька-блiзенька i так духмяна пахнуць кветкi, што разам жадаеш плакаць i смяяцца... 😂',
                renderAsset: ({ images }) => <img src={images?.[4]} />,
            },
            loseExplanation: {
                text: 'Што за ашуканства?! Не смей прысвойваць сабе мае утворы! 🤬 Або выберы ўсе свае',
                renderAsset: () => getRandomFromArray(INCORRECT_EXPLANATION_ASSETS),
            },
        },
        language: 'by',
    },
    // 26
    {
        type: RoundType.INPUT_CORRECT_ANSWER,
        content: {
            title: {
                text: 'Дыяна, а якая ў цябе мара? ✨',
            },
            correctAnswer: ['*'],
            winExplanation: {
                text: 'Здаецца мне, што цяпер як мінімум два чаlovекі яе ведаюць 😉. Альбо ты напісала якую-небудзь бязглуздзіцу, тады два чалавекі будуць ведаць бязглуздзіцу 😂 Ну а калі ты напісала нешта занадта інтымнае і насамрэч не хацела дзяліцца, то скажаш, не буду глядзець. Будзь асцярожная ў інтэрнэце, нікому нельга давяраць, толькі мне 😛',
                renderAsset: () => getRandomFromArray(CORRECT_EXPLANATION_ASSETS),
            },
            variant: 'textarea',
        },
        language: 'by',
        logMessage: 'Dream',
    },
    // 27
    {
        type: RoundType.SIMPLE_QUIZ,
        content: {
            isSingleChoice: true,
            title: {
                text: 'Мам яшчэ адно пытаннечка 🙋‍♂️ Якой с гэтых рыс твайго характару я захапляюся больш за ўсё?',
            },
            options: [
                { text: 'Сумленнасцю 🫶' },
                { text: 'Дабрынёй 😊' },
                { text: 'Упартасцю 🐑' },
                { text: 'Працавітасцю 📅' },
            ],
            correctOptionIndexes: [0, 1, 2, 3],
            winExplanation: {
                text: '"І тое, што нам блізка, кранае мае струны 🎶". Усімі, Дыяна, усімі 🤗 Так, і нават упартасцю, у нас тут у гэтым спаборніцтва 😁',
                renderAsset: () => getRandomFromArray(CORRECT_EXPLANATION_ASSETS),
            },
        },
        language: 'by',
    },
    // 28
    {
        type: RoundType.PREVIEW,
        content: {
            images: ['https://assets.happybirthdaydianayasenko.com/config1/content/cat_ok.jpg'],
            renderDescription: ({ images }) => (
                <>
                    <p>Ухх, ну і далёка ж ты зайшла!</p>
                    <img src={images?.[0]} height={160} />
                    <p>
                        Можна перадыхнуць, падсілкавацца, напрыклад, падаранай табе шакаладкай 🍫, гэта допінг для
                        мозгу. Кавуські выпіць ☕.
                    </p>
                    <p>
                        І, калі ты ўжо тут, то давядзецца скарыстацца, так бы мовіць, службовым становішчам, і запытацца
                        што-небудзь пра Жэню 😅 А то ён стараўся, прыдумляў усялякае, і ніводнага пытання. Ніпарадак!
                    </p>
                    <p>Сардэчна запрашаем пані Дыяну 👩🏻‍⚕️ далей </p>
                </>
            ),
        },
        language: 'by',
        showHeader: false,
    },
    // 29
    {
        type: RoundType.INPUT_CORRECT_ANSWER,
        content: {
            images: ['https://assets.happybirthdaydianayasenko.com/config1/content/me_birthday_article.jpg'],
            title: {
                text: 'Which city was I born in?',
                isMorseCode: true,
            },
            correctAnswer: ['Слоним', 'Слонім', 'Slonim', 'Слониме', 'Слоніме', 'В Слониме', 'У Слоніме', 'In Slonim'],
            winExplanation: {
                text: '"Дзе мой родны кут? Дзе мае аблокі? 🎶" Дакладна! А вось і артыкул, я не хлусіў, калі казаў, что пра мяне ад нараджэння газеты пісалі 🙂 Я зорка! 🌟',
                renderAsset: ({ images }) => <img src={images?.[0]} />,
            },
            loseExplanation: {
                text: 'Штосьці пайшло не так 😢 Але уздымі твар угару! Заўсёды можна загугліць 🫣',
                renderAsset: () => getRandomFromArray(INCORRECT_EXPLANATION_ASSETS),
            },
        },
        language: 'by',
        hintOptions: [
            { text: 'Гэта не баг', showAt: 0 },
            { text: 'Працяжнік кропка, працяжнік кропка', showFrom: 1 },
        ],
    },
    // 30
    {
        type: RoundType.DRAG_AND_DROP,
        content: {
            images: [
                'https://assets.happybirthdaydianayasenko.com/config1/content/me_green_card_2.jpg',
                'https://assets.happybirthdaydianayasenko.com/config1/content/me_green_card_8.jpg',
                'https://assets.happybirthdaydianayasenko.com/config1/content/me_green_card_6.jpg',
                'https://assets.happybirthdaydianayasenko.com/config1/content/me_green_card_4.jpg',
                'https://assets.happybirthdaydianayasenko.com/config1/content/me_green_card_10.jpg',
            ],
            title: {
                text: 'А паспрабуй упарадкаваць 😏. Ад старога фота (зверху) да новага (знізу). Тут розніца паміж усімі фота 2 гады, каб табе было крышачку лягчэй 🙂',
            },
            options: [
                {
                    id: 1,
                    renderAsset: ({ images }) => <img src={images?.[0]} />,
                },
                {
                    id: 2,
                    renderAsset: ({ images }) => <img src={images?.[1]} />,
                },
                {
                    id: 3,
                    renderAsset: ({ images }) => <img src={images?.[2]} />,
                },
                {
                    id: 4,
                    renderAsset: ({ images }) => <img src={images?.[3]} />,
                },
                {
                    id: 5,
                    renderAsset: ({ images }) => <img src={images?.[4]} />,
                },
            ],
            correctOrder: [1, 4, 3, 2, 5],
            winExplanation: {
                text: 'Здолела! Звонку мяняюся, а ўнутры ўсё тое ж барадатае і лысае дзіця 🥲',
                renderAsset: () => getRandomFromArray(CORRECT_EXPLANATION_ASSETS),
            },
            loseExplanation: {
                text: 'Не, я не Бенджамін Батан, я не ўмею маладзець 😶',
                renderAsset: () => getRandomFromArray(INCORRECT_EXPLANATION_ASSETS),
            },
        },
        language: 'by',
    },
    // 31
    {
        type: RoundType.SIMPLE_QUIZ,
        content: {
            images: [
                'https://assets.happybirthdaydianayasenko.com/config1/content/wolverine.webp',
                'https://assets.happybirthdaydianayasenko.com/config1/content/moose.webp',
                'https://assets.happybirthdaydianayasenko.com/config1/content/squirrel.webp',
                'https://assets.happybirthdaydianayasenko.com/config1/content/bear.webp',
            ],
            isSingleChoice: true,
            title: {
                text: `Дарэчы, мы нядаўна сядзелі і абмяркоўвалі, хто на якую жывёлу падобны ў нашай сям'і. Сышліся на гэтых. Як лічыш, хто з іх я? 😅`,
            },
            options: [
                {
                    renderAsset: ({ images }) => <img src={images?.[0]} />,
                    explanation: {
                        text: 'Гэта штука завецца расамаха, і мы вырашылі что гэта Зміцер 😅',
                        renderAsset: () => getRandomFromArray(INCORRECT_EXPLANATION_ASSETS),
                    },
                },
                {
                    renderAsset: ({ images }) => <img src={images?.[1]} />,
                    explanation: {
                        text: 'Спачатку я не згаджаўся, хто ж жадае быць ласём 😅. Але калі падумаць, то так, можна знайсьці шмат падабенстваў, і ў знешнім выглядзе, і ў характары. Так што цяпер я фанат ласёў, хтосьці ж павінен іх любіць 😄. А то ім крыўдна.',
                        renderAsset: () => getRandomFromArray(CORRECT_EXPLANATION_ASSETS),
                    },
                },
                {
                    renderAsset: ({ images }) => <img src={images?.[2]} />,
                    explanation: {
                        text: 'Не, вавёрачка гэта наша мама. І ты 🫠',
                        renderAsset: () => getRandomFromArray(INCORRECT_EXPLANATION_ASSETS),
                    },
                },
                {
                    renderAsset: ({ images }) => <img src={images?.[3]} />,
                    explanation: {
                        text: 'Не, нават сумневаў не было што мядзведзь - гэта наш тата 🐻',
                        renderAsset: () => getRandomFromArray(INCORRECT_EXPLANATION_ASSETS),
                    },
                },
            ],
            correctOptionIndexes: [1],
        },
        hintOptions: [{ text: 'Ну такі, высокі і гарбаты', showFrom: 1 }],
        language: 'by',
    },
    // 32
    {
        type: RoundType.SIMPLE_QUIZ,
        content: {
            images: [
                'https://assets.happybirthdaydianayasenko.com/config1/content/me_bald.jpg',
                'https://assets.happybirthdaydianayasenko.com/config1/content/lukashenko.jpg',
            ],
            isSingleChoice: true,
            title: {
                text: 'Памятаеш, як ты мне дапамагла выбраць аналізы 🧪? А ці памятаеш, як зваўся комплекс? ⏲️',
            },
            options: [
                {
                    text: 'VIP-агляд для мужчын',
                    explanation: {
                        text: 'Не, мне адзін тады яшчэ не доктар забараніў марнаваць грошы на такую ​​бздуру 🤑',
                        renderAsset: () => getRandomFromArray(INCORRECT_EXPLANATION_ASSETS),
                    },
                },
                {
                    text: 'Дыягностыка пры выпадзенні валос',
                    explanation: {
                        text: 'Нажаль, тут ужо нічога не дапаможа. Але мне норм 🦲',
                        renderAsset: ({ images }) => <img src={images?.[0]} />,
                    },
                },
                {
                    text: 'Здоров ты – здорова страна',
                    explanation: {
                        text: 'Маладзец, чэсна праиграла этат раунд 👍 Диана 3 працэнта!',
                        renderAsset: ({ images }) => <img src={images?.[1]} />,
                    },
                },
                {
                    text: 'Студэнцкі',
                    explanation: {
                        text: 'Дзякуй вялікі 🤗 Нам, мутантам, трэба абследавацца.',
                        renderAsset: () => getRandomFromArray(CORRECT_EXPLANATION_ASSETS),
                    },
                },
                {
                    text: 'Мужчынскае здароўе 30+',
                    explanation: {
                        text: 'Хутка 😰',
                        renderAsset: () => getRandomFromArray(INCORRECT_EXPLANATION_ASSETS),
                    },
                },
            ],
            correctOptionIndexes: [3],
        },
        timerOptions: {
            duration: DEFAULT_TIMER_DURATION,
            loseByTimerExplanation: {
                text: 'Час мінуў! 😱',
                renderAsset: () => getRandomFromArray(INCORRECT_EXPLANATION_ASSETS),
            },
        },
        language: 'by',
    },
    // 33
    {
        type: RoundType.SIMPLE_QUIZ,
        content: {
            images: ['https://assets.happybirthdaydianayasenko.com/config1/content/me_funny.jpg'],
            title: {
                text: 'А які ён, гэты мутант? 🤭',
                renderAsset: ({ images }) => <img src={images?.[0]} />,
            },
            options: [{ text: '"Мега"' }, { text: '"Добрый"' }, { text: '"Хороший"' }],
            correctOptionIndexes: [0, 1, 2],
            winExplanation: {
                text: 'Тааааак! Толькі яшчэ крыху "верабейка-сацыяфабушка" 🥲. Дарэчы, ўсё-такі ўсім падабаецца, калі іх хваляць, я чамусьці ўвесь час пра гэта забываю 😳',
                renderAsset: () => getRandomFromArray(CORRECT_EXPLANATION_ASSETS),
            },
            loseExplanation: {
                text: 'Выдаецца мне што чагосьці з спісу не хапае. Гэта ж не мая прыдумка 😜',
                renderAsset: () => getRandomFromArray(INCORRECT_EXPLANATION_ASSETS),
            },
        },
        language: 'by',
    },
    // 34
    {
        type: RoundType.PRESS_BUTTON,
        content: {
            images: ['https://assets.happybirthdaydianayasenko.com/config1/content/me_cute.jpg'],
            title: {
                text: 'Ну а раз ўвесь такі цудоўны, то, лічу, што і лайкнуць не шкада. А то хтосьці мяне змахнуў направа толькі адзін раз, гэтага недастаткова! 😛',
                renderAsset: ({ images }) => <img src={images?.[0]} />,
            },
            winExplanation: {
                text: 'Я растаў 🫠🫠🫠. Дарэчы, па задумцы тут павінна было быць тое самае фота з Тындера са шкарпэткамі ў чырвоную палоску. Але я яго не знайшоў. R.I.P. 🪦, добрае фота было. Ты застанешся у нашай памяці 😔',
                // TODO: bug, renderAsset is called twice because of changing "overlayVisible". As temporary solution I use static asset
                renderAsset: () => CORRECT_EXPLANATION_ASSETS[10],
            },
        },
        language: 'by',
    },
    // 35
    {
        type: RoundType.PREVIEW,
        content: {
            title: {
                text: '🎉 Она УЛЫБАЛАСЬ, пока не увидела ЭТО! 😱 Шокирующее поздравление для Дианы! 🤯',
            },
            renderDescription: () => (
                <iframe
                    src="https://www.youtube.com/embed/_YIUGs0gYf4"
                    title="Делаем вид что не интересно, но всё равно кликаем и смотрим 🙂"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                    style={{ border: 'none', aspectRatio: '9 / 16', height: '70vh' }}
                />
            ),
        },
        showHeader: false,
    },
    // 36
    {
        type: RoundType.SIMPLE_QUIZ,
        content: {
            videos: [
                'https://assets.happybirthdaydianayasenko.com/config1/incorrectAnswers/video3.webm',
                'https://assets.happybirthdaydianayasenko.com/config1/content/diana_first_sticker.webm',
                'https://assets.happybirthdaydianayasenko.com/config1/correctAnswers/video8.webm',
                'https://assets.happybirthdaydianayasenko.com/config1/incorrectAnswers/video6.webm',
            ],
            isSingleChoice: true,
            title: {
                text: 'Так, вяртаемся да важных спраў ☝️, гэта яшчэ не канец, яшчэ памучаю цябе 😉 Ты ведаеш, якiм было мае першае паведамленне табе. А ці ведаеш ты, які быў першы стыкер?',
            },
            options: [
                {
                    renderAsset: ({ videos }) => (
                        <video autoPlay loop muted playsInline>
                            <source src={videos?.[0]} type="video/webm" />
                        </video>
                    ),
                    explanation: {
                        text: 'Нейкі сумны, ці не так? А вось твой першы. Адразу відаць хто весялей па жыцці 😅',
                        renderAsset: ({ videos }) => (
                            <video autoPlay loop muted playsInline>
                                <source src={videos?.[1]} type="video/webm" />
                            </video>
                        ),
                    },
                },
                {
                    renderAsset: ({ videos }) => (
                        <video autoPlay loop muted playsInline>
                            <source src={videos?.[2]} type="video/webm" />
                        </video>
                    ),
                    explanation: {
                        text: 'Безумоўна, клоўны - наша ўсё 🤡, але гэта няправільны адказ',
                        renderAsset: () => getRandomFromArray(INCORRECT_EXPLANATION_ASSETS),
                    },
                },
                {
                    renderAsset: ({ videos }) => (
                        <video autoPlay loop muted playsInline>
                            <source src={videos?.[3]} type="video/webm" />
                        </video>
                    ),
                    explanation: {
                        text: 'Ахахахаххаха, не, ўсё было не настолькі дрэнна 😂',
                        renderAsset: () => getRandomFromArray(INCORRECT_EXPLANATION_ASSETS),
                    },
                },
            ],
            correctOptionIndexes: [0],
        },
        language: 'by',
    },
    // 37
    {
        type: RoundType.SIMPLE_QUIZ,
        content: {
            images: ['https://assets.happybirthdaydianayasenko.com/config1/content/me_moustache.jpg'],
            isSingleChoice: true,
            title: {
                text: 'Я доўга нават не ведаў твайго голасу. А пра што было тваё першае галасавое? 🎤',
            },
            options: [
                {
                    text: 'Пра лекі 💊',
                    explanation: {
                        text: 'Не, гэта было ўжо пасля, не першае 🙂',
                        renderAsset: () => getRandomFromArray(INCORRECT_EXPLANATION_ASSETS),
                    },
                },
                {
                    text: 'Пра штосьці смешнае 😂',
                    explanation: {
                        text: 'Не, за смех і крынж адказваю я 😑',
                        renderAsset: () => getRandomFromArray(INCORRECT_EXPLANATION_ASSETS),
                    },
                },
                {
                    text: 'Пра універсітэт 🎓',
                    explanation: {
                        text: 'Не, памылачка. И, дарэчы, ну вось, скончыўся ўніверсітэт (амаль), і навошта ты так турбавалася? Ці каштавала яно таго? Не турбуйся і аб сённяшніх праблемах 🚩!',
                        renderAsset: () => getRandomFromArray(INCORRECT_EXPLANATION_ASSETS),
                    },
                },
                {
                    text: 'Пра вусы 🥸',
                    explanation: {
                        text: 'Ну, як скажаш 😄. А голас у цябе, як я ўжо казаў, вельмі падыходзіць да знешнасці. І гэты 👩🏻‍⚕️ смайлік таксама 🤣',
                        renderAsset: ({ images }) => (
                            <>
                                <div
                                    style={{
                                        width: '12rem',
                                        height: '12rem',
                                        margin: '0 auto',
                                        marginBottom: '0.5rem',
                                    }}
                                >
                                    <img src={images?.[0]} />
                                </div>
                                <audio
                                    controls
                                    src="https://assets.happybirthdaydianayasenko.com/config1/content/diana_first_voice_message.mp3"
                                    style={{ marginLeft: '-1.375rem' }}
                                />
                            </>
                        ),
                    },
                },
            ],
            correctOptionIndexes: [3],
        },
        language: 'by',
    },
    // 38
    {
        type: RoundType.CARRY,
        content: {
            title: {
                text: 'Мне здаецца ты здагадаешся 🙃',
            },
            items: [
                { draggable: { content: { emoji: '🧠' } }, droppable: { content: { emoji: '❤️' } } },
                { draggable: { content: { text: 'абидки' } }, droppable: { content: { emoji: '🗑️' } } },
                { draggable: { content: { emoji: '👩🏻‍⚕️' } }, droppable: { content: { emoji: '💨' } } },
                { draggable: { content: { emoji: '👨‍💻' } }, droppable: { content: { emoji: '🤡' } } },
            ],
            winExplanation: {
                text: 'Так, усё правільна! 🎊 Нават калі нешта здаецца нелагічным 😉',
                renderAsset: () => getRandomFromArray(CORRECT_EXPLANATION_ASSETS),
            },
            loseExplanation: {
                text: 'Ууууупс, памылачка выйшла ❌. Але ты не хвалюйся, наступным разам справішся',
                renderAsset: () => getRandomFromArray(INCORRECT_EXPLANATION_ASSETS),
            },
        },
        hintOptions: [
            { text: 'Яўгену відавочна месца ў цырку', showAt: 2 },
            { text: '"Ты ПАДАБАЕШСЯ майму МОГЗУ 😍', showAt: 3 },
        ],
        language: 'by',
    },
    // 39
    {
        type: RoundType.PREVIEW,
        content: {
            images: ['https://assets.happybirthdaydianayasenko.com/config1/correctAnswers/image3.webp'],
            renderDescription: ({ images }) => (
                <>
                    <p>
                        <i>
                            <q>Ну хз, мы разные</q>
                        </i>
                        . Может не такие уж и разные, если ты аж до сюда дошла и всё знаешь 😜.{' '}
                        <i>
                            <q>Это не техника дошла, а Диана сама сюда дошла, на лыжах!</q>
                        </i>
                    </p>
                    <img src={images?.[0]} height={200} />
                    <p>
                        Ладно, тут немного осталось, ты почти у цели. Продолжай в том же духе, а ещё улыбнись прямо
                        сейчас! 😀
                    </p>
                </>
            ),
        },
        showHeader: false,
    },
    // 40
    {
        type: RoundType.SIMPLE_QUIZ,
        content: {
            isSingleChoice: true,
            title: {
                text: 'Ты, кстати, помнишь кто первый предложил встретиться?',
                isColoredText: true,
            },
            options: [
                {
                    text: 'Диана',
                    explanation: {
                        text: '"О времена, о нравы" 😁',
                        renderAsset: () => getRandomFromArray(CORRECT_EXPLANATION_ASSETS),
                    },
                },
                {
                    text: 'Женя',
                    explanation: {
                        text: 'Не, мне было уютно одному 😅. Так что, да, по факту я это начал. Но бывает же 🙃. Притом что у меня правда не было больше причин, просто какое-то не то настроение было.',
                        renderAsset: () => getRandomFromArray(INCORRECT_EXPLANATION_ASSETS),
                    },
                },
            ],
            correctOptionIndexes: [0],
        },
    },
    // 41
    {
        type: RoundType.SIMPLE_QUIZ,
        content: {
            isSingleChoice: true,
            title: {
                text: 'Кто первый сказал "привет" при встрече? ⏲️',
            },
            options: [{ text: 'Диана' }, { text: 'Женя' }],
            correctOptionIndexes: [0, 1],
            winExplanation: {
                text: 'А я не помню 😅. Поэтому все ответы правильные. Может ты помнишь?) Ну или можно считать, что никто не сказал 😄',
                renderAsset: () => getRandomFromArray(CORRECT_EXPLANATION_ASSETS),
            },
        },
        timerOptions: {
            duration: 30000,
            loseByTimerExplanation: {
                text: 'Время вышло! 😱',
                renderAsset: () => getRandomFromArray(INCORRECT_EXPLANATION_ASSETS),
            },
        },
    },
    // 42
    {
        type: RoundType.SIMPLE_QUIZ,
        content: {
            title: {
                text: 'Какие из этих песен я тебе не скидывал? 🎶',
            },
            options: [
                {
                    renderAsset: () => (
                        <iframe
                            width="320"
                            height="180"
                            src="https://www.youtube.com/embed/jSwN2HvEdSg?si=OtXaKzXsQkDp2xXA"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin"
                            allowFullScreen
                            style={{ border: 'none' }}
                        />
                    ),
                },
                {
                    renderAsset: () => (
                        <iframe
                            width="320"
                            height="180"
                            src="https://www.youtube.com/embed/N8NcQzMQN_U?si=ob-fvx3Oiz8YbfPC"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin"
                            allowFullScreen
                            style={{ border: 'none' }}
                        />
                    ),
                },
                {
                    renderAsset: () => (
                        <iframe
                            width="320"
                            height="180"
                            src="https://www.youtube.com/embed/UZ9ac7vb8-E?si=_4tpQYqy3DiZqq4P"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin"
                            allowFullScreen
                            style={{ border: 'none' }}
                        />
                    ),
                },
                {
                    renderAsset: () => (
                        <iframe
                            width="320"
                            height="180"
                            src="https://www.youtube.com/embed/-kG7RlDD7tg?si=mquM7AW3-_e4zqKz"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin"
                            allowFullScreen
                            style={{ border: 'none' }}
                        />
                    ),
                },
                {
                    renderAsset: () => (
                        <iframe
                            width="320"
                            height="180"
                            src="https://www.youtube.com/embed/TS-Pe30damc?si=8xoA0fDE23lhNNYv"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin"
                            allowFullScreen
                            style={{ border: 'none' }}
                        />
                    ),
                },
                {
                    renderAsset: () => (
                        <iframe
                            width="320"
                            height="180"
                            src="https://www.youtube.com/embed/wUNCkXcwGRA?si=nT2aJQ9pwHkL_aFx"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin"
                            allowFullScreen
                            style={{ border: 'none' }}
                        />
                    ),
                },
            ],
            winExplanation: {
                text: 'Правильно! Но, получается, что уже и эти две скинул 😅 Надеюсь не застрянут в голове.',
                renderAsset: () => getRandomFromArray(CORRECT_EXPLANATION_ASSETS),
            },
            loseExplanation: {
                text: 'Придётся переслушать, потому что ответ неправильный 🙁. Но песни замечательные!',
                renderAsset: () => getRandomFromArray(INCORRECT_EXPLANATION_ASSETS),
            },
            correctOptionIndexes: [0, 4],
        },
    },
    // 43
    {
        type: RoundType.SIMPLE_QUIZ,
        content: {
            images: ['https://assets.happybirthdaydianayasenko.com/config1/content/mexico.jpg'],
            videos: ['https://assets.happybirthdaydianayasenko.com/config1/content/mexico_heart.webm'],
            title: {
                text: 'А помнишь, ты мне помогала составить список вещей в Мексику 🇲🇽? А то я вечно всё забываю 🙈. А что из этого было в списке? ✅',
                renderAsset: ({ images }) => <img src={images?.[0]} />,
            },
            options: [
                { text: 'Паспорт 🛂' },
                { text: 'Лекарства 💊' },
                { text: 'Очки 💊' },
                { text: 'Платье 👗' },
                { text: 'Повербанк 🔋' },
            ],
            correctOptionIndexes: [0, 1, 2, 4],
            winExplanation: {
                text: 'Так точно, Дианка! Я со временем добавил ещё пару вещей, но целом до сих пор пользуюсь им! И ничего с тех пор не забыл 🥳',
                renderAsset: ({ videos }) => (
                    <video autoPlay loop muted playsInline>
                        <source src={videos?.[0]} type="video/webm" />
                    </video>
                ),
            },
            loseExplanation: {
                text: 'Неправильно 😶. Видимо ты кое что важное забыла 🧳. Ну или ненужное добавила 😆',
                renderAsset: () => getRandomFromArray(INCORRECT_EXPLANATION_ASSETS),
            },
        },
        hintOptions: [{ text: 'Ну найди уже ответ в сообщениях 🫣', showFrom: 2 }],
    },
    // 44
    {
        type: RoundType.INPUT_CORRECT_ANSWER,
        content: {
            title: {
                text: 'Ну и напоследок. Честно-честно 🙂. Скажи, а какая разница (в минутах) во времени нашего рождения? 🐣',
            },
            correctAnswer: [
                '20',
                '20 минут',
                'двадцать',
                'двадцать минут',
                'дваццаць',
                'дваццаць хвілін',
                'twenty',
                'twenty minutes',
            ],
            winExplanation: {
                text: 'Ах ты какая, всё-то ты знаешь 😏 Может ты уже забыла, но я в начале написал, что "вдруг в конце что-то будет". Так-с, что же тут у нас...?',
                renderAsset: () => getRandomFromArray(CORRECT_EXPLANATION_ASSETS),
            },
            loseExplanation: {
                text: 'Ты что 😬. Ты так близко с финишу, нельзя неправильно отвечать',
                renderAsset: () => getRandomFromArray(INCORRECT_EXPLANATION_ASSETS),
            },
        },
        hintOptions: [{ text: 'Я родился в 6:25 🙃', showFrom: 1 }],
        logMessage: 'Passed all rounds',
    },
    // 45
    {
        type: RoundType.PREVIEW,
        content: {
            images: ['https://assets.happybirthdaydianayasenko.com/config1/content/easy_mode.png'],
            videos: ['https://assets.happybirthdaydianayasenko.com/config1/content/cat_paws.webm'],
            title: {
                text: 'Ура!!! Ты дошла до конца!!! 🎉',
                renderAsset: ({ videos }) => (
                    <video autoPlay loop muted playsInline>
                        <source src={videos?.[0]} type="video/webm" />
                    </video>
                ),
            },
            renderDescription: ({ images }) => (
                <>
                    <p>
                        Диана, если в целом, это и есть мой тебе подарок. Я надеюсь, что процесс принёс тебе
                        удовольствие и немного разнообразия в серые будни. Я правда постарался 😊 И я надеюсь, что ты не
                        будешь сегодня плакать!
                    </p>
                    <p>
                        Если что, это всё с нуля сделано, разве что электричество для компьютера не добывал сам 😅 Но
                        делать было очень интересно, учитывая для кого 🤗. Где-то годик оно ещё поживёт (не знаю правда
                        зачем, никому не покажешь по сути 😅), а там домен закончится, и уйдёт в небытие...
                    </p>
                    <p>
                        Чтобы начать заново, просто открой сайт в новой вкладке, прогресс сбросится. Если хочешь
                        попробовать попроще, ну или почитать мой искромётный юмор, то можно добавить в адресную строку
                        следущее:
                    </p>
                    <img src={images?.[0]} style={{ maxWidth: '100%' }} />
                    <div>
                        <a href="https://happybirthdaydianayasenko.com/?easyMode=true" target="_blank" rel="noreferrer">
                            Ссылочка на такой режим
                        </a>
                    </div>
                    <p>Это режим Бога, ну или Богини Артемиды 😇.</p>
                    <p>Спасибо за проведённое время, с вами приятно иметь дело! 🤗</p>
                </>
            ),
        },
        nextButtonText: 'Конец игры',
        showHeader: false,
    },
    // 46
    {
        type: RoundType.PREVIEW,
        content: {
            renderDescription: () => <CertificateDescription />,
        },
        showHeader: false,
    },
    // 47
    {
        type: RoundType.PREVIEW,
        content: {
            renderDescription: () => (
                <>
                    <p>
                        А ещё я создал тебе плейлист на ютюбчике, там разные песенки, какие-то просто нравятся, какие-то
                        даже со смыслом 😅, а какие-то я когда-то слушал и мне они приносят хорошие воспоминания.
                    </p>
                    <a
                        href="https://www.youtube.com/playlist?list=PLPPcTYYr4rpRCWNu-zY8corCIy2PCpEoF"
                        target="_blank"
                        rel="noreferrer"
                        style={{ display: 'block', marginBottom: '1.5em' }}
                    >
                        Воть он
                    </a>
                    <iframe
                        width="320"
                        height="180"
                        src="https://www.youtube.com/embed/?listType=playlist&list=PLPPcTYYr4rpRCWNu-zY8corCIy2PCpEoF"
                        title="С днём нараджэння 🎈🎈🎈"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                        style={{ border: 'none' }}
                    />
                    <p>Послушай, если хочешь, думаю тебе понравится 🙃</p>
                </>
            ),
        },
        showHeader: false,
    },
    // 48
    {
        type: RoundType.PREVIEW,
        content: {
            title: {
                text: 'А сейчас и правда конец. Спаcибо за игру 🤗 И с днём рождения ещё раз!',
            },
            renderDescription: () => (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        aspectRatio: '16 / 9',
                        margin: '0 auto',
                        fontFamily: 'Clarendon, serif',
                        fontSize: '1.5rem',
                        backgroundColor: '#000000',
                        color: '#ffffff',
                    }}
                >
                    <span>Directed by</span>
                    <span style={{ textTransform: 'uppercase' }}>Yauheni Belash</span>
                </div>
            ),
        },
        showHeader: false,
        logMessage: 'End of the game',
    },
];
