import { FC, PropsWithChildren, useContext, useEffect, useRef, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { AppContext } from '../../AppContext';
import './RoundWrapper.scss';
import { Round, Title } from '../types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLeftLong, faRepeat, faRightLong } from '@fortawesome/free-solid-svg-icons';
import Button from '../components/Button/Button';
import { INITIAL_LIVES_NUMBER } from '../../constants';
import Explanation from '../components/Explanation/Explanation';
import Timer from './Timer/Timer';
import { encodeToMorse } from '../utils';
import clsx from 'clsx';
import ColoredText from './ColoredText/ColoredText';
import '../../variables.scss';
import { i18nConfig } from '../../i18n';
import usePreloadAssets from '../hooks/usePreloadAssets';

interface RoundWrapperProps extends PropsWithChildren {
    title?: Title;
    showHeader?: boolean;
    isWin?: boolean;
    isLose?: boolean;
    isLoseByTimer?: boolean;
    withoutResetButton?: boolean;
    resetRound?: () => void;
    forceLose?: () => void;
}

const getLoseExplanation = (isLoseByTimer: boolean, content: Round['content'], timerOptions: Round['timerOptions']) => {
    let loseExplanation;

    if (isLoseByTimer && timerOptions && 'loseByTimerExplanation' in timerOptions) {
        loseExplanation = timerOptions.loseByTimerExplanation;
    } else if ('loseExplanation' in content) {
        // eslint-disable-next-line @typescript-eslint/prefer-destructuring
        loseExplanation = content.loseExplanation;
    }

    return loseExplanation;
};

const RoundWrapper: FC<RoundWrapperProps> = ({
    children,
    title,
    showHeader = true,
    isWin = false,
    isLose = false,
    isLoseByTimer = false,
    withoutResetButton = false,
    resetRound,
    forceLose,
}) => {
    const isLoseFinal = isLose || isLoseByTimer;
    const {
        rounds,
        progress: { currentRoundIndex, livesLeft, rounds: progressRounds },
        goToPreviousRound,
        tryAgain,
        goToNextRound,
        isEasyMode,
        logStuff,
    } = useContext(AppContext);
    const currentRound = rounds[currentRoundIndex];
    const { content, timerOptions, hintOptions, language = 'ru', nextButtonText } = currentRound;
    const { loseCount } = progressRounds[currentRoundIndex];

    const [isContentVisible, setIsContentVisible] = useState(false);
    const [footerHeight, setFooterHeight] = useState<number | null>(null);
    const footerRef = useRef<HTMLElement>(null);
    const openingOfFirstRoundWasLogged = useRef(false);

    useEffect(() => {
        setIsContentVisible(true);
    }, []);

    useEffect(() => {
        if (currentRoundIndex !== 0 || openingOfFirstRoundWasLogged.current) return;

        logStuff(isEasyMode, 'Opened first round');
        openingOfFirstRoundWasLogged.current = true;
    }, []);

    usePreloadAssets(rounds, currentRoundIndex);

    useEffect(() => {
        if (isEasyMode || !hintOptions || hintOptions.length === 0 || isWin) return;

        const hintsToShow = hintOptions.filter(({ showAt, showFrom }) => {
            const showAtFilterResult = loseCount === undefined ? showAt === 0 : showAt === loseCount;
            const showFromFilterResult =
                loseCount === undefined ? showFrom === 0 : showFrom !== undefined && showFrom <= loseCount;

            return showAtFilterResult || showFromFilterResult;
        });

        hintsToShow.forEach(({ text }) => {
            toast(text, { icon: '💡', style: { border: '1px solid var(--neutral-color)' } });
        });
    }, [loseCount]);

    const showPreviousRoundButton = isEasyMode && currentRoundIndex > 0 && (isWin || isLoseFinal);
    // eslint-disable-next-line no-nested-ternary
    const showResetRoundButton = withoutResetButton ? false : isEasyMode ? isWin || isLoseFinal : isLoseFinal;
    const showNextRoundButton = isWin && currentRoundIndex < rounds.length - 1;
    const showFooter = showPreviousRoundButton || showResetRoundButton || showNextRoundButton;

    useEffect(() => {
        if (!footerRef.current) return;

        const height = footerRef.current.offsetHeight;
        setFooterHeight(height);
    }, [showFooter]);

    const handleResetRound = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        resetRound?.();
        tryAgain();
    };

    const loseExplanation = getLoseExplanation(isLoseByTimer, content, timerOptions);
    const showHeaderFinal = showHeader && !isEasyMode;

    return (
        <div className="round">
            {showHeaderFinal && (
                <header className="round-header">
                    <div className="lives">
                        {Array(INITIAL_LIVES_NUMBER)
                            .fill(null)
                            .map((_, index) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <span key={index}>{index < livesLeft ? '❤️' : '💔'}</span>
                            ))}
                    </div>
                    {timerOptions && !isWin && !isLoseFinal && (
                        <Timer duration={timerOptions.duration} onTimerPassed={forceLose} />
                    )}
                </header>
            )}
            <div
                className={clsx('round-content', { 'round-content--visible': isContentVisible })}
                style={{
                    minHeight: showHeaderFinal ? `calc(100vh - 5.313rem)` : '100vh',
                    marginBottom: showFooter ? `${footerHeight}px` : 0,
                }}
            >
                {title && (
                    <div className="round-title">
                        <h1
                            className={clsx('round-title-text', {
                                'round-title-text--upside-down': Boolean(title.isUpsideDown),
                                'round-title-text--morse-code': Boolean(title.isMorseCode),
                            })}
                        >
                            {title.isMorseCode === true && encodeToMorse(title.text)}
                            {title.isColoredText === true && <ColoredText text={title.text} language={language} />}
                            {title.isMorseCode !== true && title.isColoredText !== true && title.text}
                        </h1>
                        {title.renderAsset !== undefined && (
                            <div className="round-title-asset">
                                {title.renderAsset({ images: content.images, videos: content.videos })}
                            </div>
                        )}
                    </div>
                )}
                {children}
                {isWin && 'winExplanation' in content && (
                    <Explanation
                        isCorrect
                        {...content.winExplanation}
                        images={content.images}
                        videos={content.videos}
                    />
                )}
                {isLoseFinal && loseExplanation && (
                    <Explanation isIncorrect {...loseExplanation} images={content.images} videos={content.videos} />
                )}
            </div>
            {showFooter && (
                <footer ref={footerRef} className="round-footer">
                    <div>
                        {showPreviousRoundButton && (
                            <Button icon={<FontAwesomeIcon icon={faLeftLong} />} onClick={goToPreviousRound}>
                                {i18nConfig.back[language]}
                            </Button>
                        )}
                    </div>
                    <div>
                        {showResetRoundButton && (
                            <Button icon={<FontAwesomeIcon icon={faRepeat} />} onClick={handleResetRound}>
                                {i18nConfig.tryAgain[language]}
                            </Button>
                        )}
                    </div>
                    <div>
                        {showNextRoundButton && (
                            <Button
                                icon={<FontAwesomeIcon icon={faRightLong} />}
                                iconPosition="right"
                                onClick={() => {
                                    goToNextRound();
                                }}
                            >
                                {nextButtonText ?? i18nConfig.next[language]}
                            </Button>
                        )}
                    </div>
                </footer>
            )}
            {hintOptions && !isEasyMode && <Toaster position="bottom-left" />}
        </div>
    );
};

export default RoundWrapper;
