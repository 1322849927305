import { useContext, useEffect } from 'react';
import { Round } from '../types';
import {
    CORRECT_EXPLANATION_IMAGE_PATHS,
    CORRECT_EXPLANATION_VIDEO_PATHS,
    INCORRECT_EXPLANATION_IMAGE_PATHS,
    INCORRECT_EXPLANATION_VIDEO_PATHS,
} from '../constants';
import { AppContext, AppContextProps } from '../../AppContext';

const preloadImages = (
    images: string[],
    preloadAssetsCache: AppContextProps['preloadAssetsCache'],
    cachePreloadedAssets: AppContextProps['cachePreloadedAssets']
) => {
    const imagesPathsToCache: string[] = [];

    images.forEach((image) => {
        const value = preloadAssetsCache.get(image);

        if (value !== undefined) return;

        const img = new Image();
        img.src = image;

        imagesPathsToCache.push(image);
    });

    cachePreloadedAssets(imagesPathsToCache);
};

const preloadVideos = (
    videos: string[],
    preloadAssetsCache: AppContextProps['preloadAssetsCache'],
    cachePreloadedAssets: AppContextProps['cachePreloadedAssets']
) => {
    const videosPathsToCache: string[] = [];

    videos.forEach((videoSrc) => {
        const value = preloadAssetsCache.get(videoSrc);

        // This is needed instead of simple has() and boolean because preloading of videos works badly, so we give 2 chances to load it
        if (value !== undefined && value >= 2) return;

        const videoElement = document.createElement('video');
        videoElement.src = videoSrc;
        videoElement.preload = 'auto';
        videoElement.load();

        videosPathsToCache.push(videoSrc);
    });

    cachePreloadedAssets(videosPathsToCache);
};

const usePreloadAssets = (rounds: Round[], currentRoundIndex: number) => {
    const { preloadAssetsCache, cachePreloadedAssets } = useContext(AppContext);

    useEffect(() => {
        const imagesToPreload = [...CORRECT_EXPLANATION_IMAGE_PATHS, ...INCORRECT_EXPLANATION_IMAGE_PATHS];
        const videosToPreload = [...CORRECT_EXPLANATION_VIDEO_PATHS, ...INCORRECT_EXPLANATION_VIDEO_PATHS];
        preloadImages(imagesToPreload, preloadAssetsCache, cachePreloadedAssets);
        preloadVideos(videosToPreload, preloadAssetsCache, cachePreloadedAssets);
    }, []);

    useEffect(() => {
        if (currentRoundIndex + 1 >= rounds.length) return;

        const nextRound = rounds[currentRoundIndex + 1];

        const { images = [], videos = [] } = nextRound.content;

        preloadImages(images, preloadAssetsCache, cachePreloadedAssets);
        preloadVideos(videos, preloadAssetsCache, cachePreloadedAssets);
    }, []);
};

export default usePreloadAssets;
