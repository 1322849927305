export const CORRECT_EXPLANATION_IMAGE_PATHS = [
    'https://assets.happybirthdaydianayasenko.com/config1/correctAnswers/image1.webp',
    'https://assets.happybirthdaydianayasenko.com/config1/correctAnswers/image2.webp',
    'https://assets.happybirthdaydianayasenko.com/config1/correctAnswers/image3.webp',
    'https://assets.happybirthdaydianayasenko.com/config1/correctAnswers/image4.webp',
    'https://assets.happybirthdaydianayasenko.com/config1/correctAnswers/image5.webp',
    'https://assets.happybirthdaydianayasenko.com/config1/correctAnswers/image6.webp',
];

export const CORRECT_EXPLANATION_VIDEO_PATHS = [
    'https://assets.happybirthdaydianayasenko.com/config1/correctAnswers/video1.webm',
    'https://assets.happybirthdaydianayasenko.com/config1/correctAnswers/video2.webm',
    'https://assets.happybirthdaydianayasenko.com/config1/correctAnswers/video3.webm',
    'https://assets.happybirthdaydianayasenko.com/config1/correctAnswers/video4.webm',
    'https://assets.happybirthdaydianayasenko.com/config1/correctAnswers/video5.webm',
    'https://assets.happybirthdaydianayasenko.com/config1/correctAnswers/video6.webm',
    'https://assets.happybirthdaydianayasenko.com/config1/correctAnswers/video7.webm',
    'https://assets.happybirthdaydianayasenko.com/config1/correctAnswers/video8.webm',
    'https://assets.happybirthdaydianayasenko.com/config1/correctAnswers/video9.webm',
    'https://assets.happybirthdaydianayasenko.com/config1/correctAnswers/video10.webm',
    'https://assets.happybirthdaydianayasenko.com/config1/correctAnswers/video11.webm',
    'https://assets.happybirthdaydianayasenko.com/config1/correctAnswers/video12.webm',
    'https://assets.happybirthdaydianayasenko.com/config1/correctAnswers/video13.webm',
];

export const INCORRECT_EXPLANATION_IMAGE_PATHS = [
    'https://assets.happybirthdaydianayasenko.com/config1/incorrectAnswers/image1.webp',
    'https://assets.happybirthdaydianayasenko.com/config1/incorrectAnswers/image2.webp',
    'https://assets.happybirthdaydianayasenko.com/config1/incorrectAnswers/image3.webp',
    'https://assets.happybirthdaydianayasenko.com/config1/incorrectAnswers/image4.webp',
    'https://assets.happybirthdaydianayasenko.com/config1/incorrectAnswers/image5.webp',
];

export const INCORRECT_EXPLANATION_VIDEO_PATHS = [
    'https://assets.happybirthdaydianayasenko.com/config1/incorrectAnswers/video1.webm',
    'https://assets.happybirthdaydianayasenko.com/config1/incorrectAnswers/video2.webm',
    'https://assets.happybirthdaydianayasenko.com/config1/incorrectAnswers/video3.webm',
    'https://assets.happybirthdaydianayasenko.com/config1/incorrectAnswers/video4.webm',
    'https://assets.happybirthdaydianayasenko.com/config1/incorrectAnswers/video5.webm',
    'https://assets.happybirthdaydianayasenko.com/config1/incorrectAnswers/video6.webm',
    'https://assets.happybirthdaydianayasenko.com/config1/incorrectAnswers/video7.webm',
    'https://assets.happybirthdaydianayasenko.com/config1/incorrectAnswers/video8.webm',
    'https://assets.happybirthdaydianayasenko.com/config1/incorrectAnswers/video9.webm',
    'https://assets.happybirthdaydianayasenko.com/config1/incorrectAnswers/video10.webm',
    'https://assets.happybirthdaydianayasenko.com/config1/incorrectAnswers/video11.webm',
    'https://assets.happybirthdaydianayasenko.com/config1/incorrectAnswers/video12.webm',
    'https://assets.happybirthdaydianayasenko.com/config1/incorrectAnswers/video13.webm',
    'https://assets.happybirthdaydianayasenko.com/config1/incorrectAnswers/video14.webm',
];

const CORRECT_EXPLANATION_IMAGES = CORRECT_EXPLANATION_IMAGE_PATHS.map((imagePath) => (
    <img key={imagePath} src={imagePath} />
));
const INCORRECT_EXPLANATION_IMAGES = INCORRECT_EXPLANATION_IMAGE_PATHS.map((imagePath) => (
    <img key={imagePath} src={imagePath} />
));
const CORRECT_EXPLANATION_VIDEOS = CORRECT_EXPLANATION_VIDEO_PATHS.map((videoPath) => (
    <video key={videoPath} autoPlay loop muted playsInline>
        <source src={videoPath} type="video/webm" />
    </video>
));
const INCORRECT_EXPLANATION_VIDEOS = INCORRECT_EXPLANATION_VIDEO_PATHS.map((videoPath) => (
    <video key={videoPath} autoPlay loop muted playsInline>
        <source src={videoPath} type="video/webm" />
    </video>
));

export const CORRECT_EXPLANATION_ASSETS = [...CORRECT_EXPLANATION_IMAGES, ...CORRECT_EXPLANATION_VIDEOS];
export const INCORRECT_EXPLANATION_ASSETS = [...INCORRECT_EXPLANATION_IMAGES, ...INCORRECT_EXPLANATION_VIDEOS];

export const DEFAULT_TIMER_DURATION = 15000;
