import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../components/Button/Button';
import RoundWrapper from '../RoundWrapper/RoundWrapper';
import { faRepeat } from '@fortawesome/free-solid-svg-icons';
import { useContext } from 'react';
import { AppContext } from '../../AppContext';
import './GameOver.scss';

const GameOver = () => {
    const { startAgain } = useContext(AppContext);

    return (
        <RoundWrapper title={{ text: 'Гейм овер 😢' }} showHeader={false}>
            <div className="game-over">
                <div className="preview-description">
                    <img
                        height="256"
                        src="https://assets.happybirthdaydianayasenko.com/config1/content/game_over.jpg"
                        alt="sad cat"
                    />
                    <p>
                        Да, жестоко 😑. Особенно в день рождения. А впереди ещё хватает интересностей... 😜 Но{' '}
                        <q>дорогу осилит идущий</q>, а тут не просто идущий, а Диана со cвоим упорством.
                    </p>
                    <p>Так что, будем считать это досадным недоразумением и сделаем вид что не заметили.</p>
                </div>
                <Button icon={<FontAwesomeIcon icon={faRepeat} />} onClick={startAgain}>
                    Начни игру заново
                </Button>
            </div>
        </RoundWrapper>
    );
};

export default GameOver;
