import { useContext, useState } from 'react';
import './CertificateDescription.scss';
import { AppContext } from '../../AppContext';

const CertificateDescription = () => {
    const { isEasyMode, logStuff } = useContext(AppContext);
    const [showRest, setShowRest] = useState(false);
    return (
        <>
            <p>
                Ахахахахахахах, Ясенко, ну какой конец, ты серьёзно 😂😂😂? Ну то есть, конечно, игры конец, но это ещё
                не всё.
            </p>
            <p>Без настоящего подарка тоже не уйдёшь!</p>
            <img src="https://assets.happybirthdaydianayasenko.com/config1/content/cat_present.png" height={200} />
            <p>
                Хотя кто знает, может и уйдёшь 😂 Диана временами умеет удивлять 🤭 Я ссылочку оставлю, пусть она
                симпатично горит, а ты как хочешь, можешь нажимать, можешь не нажимать. Я бы нажал 🙃
            </p>
            <a
                className="button certificate-link"
                href="https://assets.happybirthdaydianayasenko.com/config1/content/certificate.pdf"
                target="_blank"
                rel="noreferrer"
                download="example.pdf"
                onClick={() => {
                    setShowRest(true);
                    logStuff(isEasyMode, 'Clicked on certificate link');
                }}
            >
                Нажимай сюда
            </a>
            {showRest ? (
                <>
                    <p>Не за что 🤗</p>
                    <video autoPlay loop muted playsInline height={200}>
                        <source
                            src="https://assets.happybirthdaydianayasenko.com/config1/content/cat_jumping.webm"
                            type="video/webm"
                        />
                    </video>
                    <p>
                        Оно, кстати, совсем не страшно, у меня мама даже пробовала. Так что, летай,{' '}
                        <i>
                            <q>у цябе цяпер ёсць крылы 🎵</q>
                        </i>
                    </p>
                </>
            ) : (
                <>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                </>
            )}
        </>
    );
};

export default CertificateDescription;
