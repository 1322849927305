import { FC } from 'react';
import { BaseRoundContent, DragAndDropItem } from '../../types';
import './Item.scss';

interface ItemProps extends DragAndDropItem {
    images: BaseRoundContent['images'];
    videos: BaseRoundContent['videos'];
}

const Item: FC<ItemProps> = ({ text, renderAsset, images, videos }) => {
    return (
        <div className="item">
            {renderAsset !== undefined && <div className="item-asset">{renderAsset({ images, videos })}</div>}
            {Boolean(text) && <span className="item-text">{text}</span>}
        </div>
    );
};

export default Item;
