type I18nConfig = Record<string, { ru: string; by: string }>;

export const i18nConfig: I18nConfig = {
    next: { ru: 'Дальше', by: 'Далей' },
    tryAgain: { ru: 'Попробуй еще раз', by: 'Спрабуй яшчэ раз' },
    back: { ru: 'Назад', by: 'Назад' },
    chooseAtLeastOneOption: { ru: 'Выбери хотя бы один вариант', by: 'Выберы хаця б адзін варыянт' },
    answer: { ru: 'Ответить', by: 'Адказаць' },
    inputAnswer: { ru: 'Введи ответ', by: 'Увядзі адказ' },
    pressMe: { ru: 'Нажимай меня', by: 'Націскай мяне' },
    press: { ru: 'Жмяк', by: 'Жмяк' },
};
