import { FC, ReactNode, createContext, useState } from 'react';
import { Answer, Progress, Round } from './rounds/types';
import { roundsConfig } from './rounds/roundsConfig';
import { EASY_MODE_SEARCH_PARAM, INITIAL_LIVES_NUMBER } from './constants';
import useSessionStorage from './rounds/hooks/useSessionStorage';

const LOG_API_URL = 'https://adap8irgs8.execute-api.us-east-1.amazonaws.com/prod/log';

export interface AppContextProps {
    rounds: Round[];
    goToNextRound: () => void;
    tryAgain: () => void;
    goToPreviousRound: () => void;
    isEasyMode: boolean;
    killLife: () => void;
    startAgain: () => void;
    progress: Progress;
    saveAnswer: (
        answer: Answer,
        isLoseByTimer: boolean,
        isEasyMode: boolean,
        willLose: boolean,
        logMessage: Round['logMessage']
    ) => void;
    preloadAssetsCache: Map<string, number>;
    cachePreloadedAssets: (assetPaths: string[]) => void;
    logStuff: (isEasyMode: boolean, logMessage: string) => void;
}

const APP_CONTEXT_DEFAULT_VALUE: AppContextProps = {
    rounds: [],
    goToNextRound: () => {
        // Do nothing
    },
    tryAgain: () => {
        // Do nothing
    },
    goToPreviousRound: () => {
        // Do nothing
    },
    isEasyMode: false,
    killLife: () => {
        // Do nothing
    },
    startAgain: () => {
        // Do nothing
    },
    progress: {
        currentRoundIndex: 0,
        livesLeft: 0,
        rounds: { 0: { answer: null } },
    },
    saveAnswer: () => {
        // Do nothing
    },
    /*
     * This "cache" exists to prevent going to real cache too often when preloading assets.
     * We just try to preload 1 (images) or 2 (videos) times.
     * Othervise every time assets will be preloaded. And even though they well be in cache already,
     * I don't like too many requests in Network tab.
     */
    preloadAssetsCache: new Map(),
    cachePreloadedAssets: () => {
        // Do nothing
    },
    logStuff: () => {
        // Do nothing
    },
};

export const AppContext = createContext<AppContextProps>(APP_CONTEXT_DEFAULT_VALUE);

interface AppContextProviderProps {
    children: ReactNode;
}

const logProgress = async (
    progress: Progress,
    isEasyMode: boolean,
    willLose: boolean,
    answer: Answer,
    logMessage: Round['logMessage']
) => {
    try {
        const response = await fetch(LOG_API_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                progress,
                isEasyMode,
                willLose,
                answer,
                logMessage,
                currentRoundIndex: progress.currentRoundIndex,
                livesLeft: progress.livesLeft,
                origin: window.location.origin,
            }),
        });

        if (!response.ok) {
            throw new Error(`Failed to log progress: ${response.statusText}`);
        }
    } catch (error) {
        console.error('Failed to log progress', error);
    }
};

const AppContextProvider: FC<AppContextProviderProps> = ({ children }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [rounds, setRounds] = useState<Round[]>(roundsConfig);
    const [preloadAssetsCache, setPreloadAssetsCache] = useState<Map<string, number>>(new Map());
    const searchParams = new URLSearchParams(window.location.search);

    const progressInitialValue: Progress = {
        currentRoundIndex: 0,
        livesLeft: INITIAL_LIVES_NUMBER,
        rounds: Object.fromEntries(rounds.map((_, index) => [index, { answer: null }])),
    };

    const [progress, setProgress] = useSessionStorage<Progress>('progress', progressInitialValue);

    const setProgressField = (field: keyof Progress, value: Progress[keyof Progress]) => {
        setProgress((progress) => ({
            ...progress,
            [field]: value,
        }));
    };

    return (
        <AppContext.Provider
            // Let's see if there will be any problems with this rule disabled
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            value={{
                rounds,
                goToNextRound: () => {
                    window.scrollTo({ top: 0 });
                    setProgressField('currentRoundIndex', progress.currentRoundIndex + 1);
                },
                tryAgain: () => {
                    const currentRoundProgress = progress.rounds[progress.currentRoundIndex];

                    setProgressField('rounds', {
                        ...progress.rounds,
                        [progress.currentRoundIndex]: { answer: null, loseCount: currentRoundProgress.loseCount },
                    });
                },
                goToPreviousRound: () => {
                    window.scrollTo({ top: 0 });
                    setProgressField('currentRoundIndex', progress.currentRoundIndex - 1);
                },
                isEasyMode: searchParams.get(EASY_MODE_SEARCH_PARAM) === 'true',
                killLife: () => {
                    setProgressField('livesLeft', progress.livesLeft - 1);
                },
                startAgain: () => {
                    setProgress(progressInitialValue);
                },
                progress,
                saveAnswer: (answer, isLoseByTimer, isEasyMode, willLose, logMessage) => {
                    setProgress((progress) => {
                        const currentRoundProgress = progress.rounds[progress.currentRoundIndex];
                        const newProgress: Progress = {
                            ...progress,
                            rounds: {
                                ...progress.rounds,
                                [progress.currentRoundIndex]: {
                                    answer,
                                    loseCount: willLose ? (currentRoundProgress.loseCount ?? 0) + 1 : undefined,
                                    ...(isLoseByTimer && { isLoseByTimer: true }),
                                },
                            },
                        };

                        void logProgress(newProgress, isEasyMode, willLose, answer, logMessage);

                        return newProgress;
                    });
                },
                preloadAssetsCache,
                cachePreloadedAssets: (assetPaths) => {
                    setPreloadAssetsCache((preloadAssetsCache) => {
                        const newCache = new Map(preloadAssetsCache);

                        assetPaths.forEach((path) => {
                            const value = newCache.get(path);

                            if (value === undefined) {
                                newCache.set(path, 1);
                            } else if (value < 2) {
                                newCache.set(path, value + 1);
                            }
                        });

                        return newCache;
                    });
                },
                // TODO: it was done very fast to be able to log not only rounds progress, but also random messages. Need to redo it.
                logStuff: (isEasyMode, logMessage) => {
                    void logProgress(progress, isEasyMode, false, false, logMessage);
                },
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

export default AppContextProvider;
