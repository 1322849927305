import { forwardRef } from 'react';
import clsx from 'clsx';
import './Input.scss';

interface InputBaseProps {
    hasError?: boolean;
    isCorrect?: boolean;
    isIncorrect?: boolean;
}

type InputProps =
    | (InputBaseProps & React.InputHTMLAttributes<HTMLInputElement> & { variant?: 'input' })
    | (InputBaseProps & React.TextareaHTMLAttributes<HTMLTextAreaElement> & { variant: 'textarea' });

// eslint-disable-next-line prefer-arrow-callback
const Input = forwardRef<HTMLInputElement | HTMLTextAreaElement, InputProps>(function Input(props, ref) {
    const { hasError, isCorrect, isIncorrect, variant, ...rest } = props;

    const commonProps = {
        ...rest,
        ref,
        className: clsx('input', {
            'input--error': hasError,
            'input--correct': isCorrect,
            'input--incorrect': isIncorrect,
        }),
    };

    return (
        <label className="input-label">
            {variant === 'textarea' ? (
                <textarea {...(commonProps as React.TextareaHTMLAttributes<HTMLTextAreaElement>)} rows={5} cols={40} />
            ) : (
                <input {...(commonProps as React.InputHTMLAttributes<HTMLInputElement>)} type="text" />
            )}
        </label>
    );
});

export default Input;
